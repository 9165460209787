import React from 'react'
import CartItems from '../Components/CartItems/CartItems'

const Checkout = () => {
  return (
    <div>
      <CartItems/>
    </div>
  )
}

export default Checkout
