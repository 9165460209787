import React from 'react'
import StoreContainer from '../Components/StoreContainer/StoreContainer'

const Store = () => {
  return (
    <div>
      <StoreContainer/>
    </div>
  )
}

export default Store
